/*Custom styles go in here - yusuf 11th Oct 2021*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.header-info .icon-cell {
  line-height: 45px;
}
.header-icon svg {
  font-size: 45px;
}

.slider-caption {
  font-size: 30px !important;
}

#welcome_wrapper .tp-caption {
  font-family: arial, sans-serif !important;
}

.wt-icon-box-md svg {
  font-size: 65px;
}

.service-box-content p {
  min-height: 180px;
}

.css-96uuyl {
  background-color: white !important;
}

.nav-register a {
  background-color: #00173c !important;
}

.upd-dly {
  padding: 0px !important;
}
.social-icons li {
  padding: 0px !important;
  /* line-height: 26px; */
}
.header-style-1 .top-bar .wt-topbar-info .social-icons li {
  border-right: none !important;
}

/* Custom color to tabs */
.wt-tabs.bg-tabs .nav-tabs > li > a {
  background-color: #00173c;
  color: #fff;
  font-weight: 400;
}

.wt-tabs.bg-tabs .nav-tabs > li > a.active {
  background-color: #ff5e15;
  color: #fff;
  border: 0 px;
}

.MuiDataGrid-root .MuiTablePagination-root p {
  margin-bottom: 0px;
}

.social-icons li a:hover {
  background-color: #fff !important;
  color: #0f1221 !important;
}

.text-strike {
  text-decoration: line-through;
}

.text-transform-none {
  text-transform: none !important;
}

.productInfoGroup {
  background-color: transparent !important;
}

.productInfoGroup .form-group {
  margin-bottom: 0px !important;
}

// Prevent selection
body {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
$header-color: #222;
$header-shadow-color: #c9c9c9;
$header-0-shadow-color: #ffab00;
.cep-error-code-wrapper {
  height: 190px;
  .cep-error-code {
    font-size: 220px;
    letter-spacing: 10px;
    margin: 0;
    font-weight: 700;
    text-shadow: 2px 2px 0 $header-shadow-color,
      -2px -2px 0 $header-shadow-color;
    color: $header-color;

    .cep-error-code-0 {
      text-shadow: 2px 2px 0 $header-0-shadow-color,
        -2px -2px 0 $header-0-shadow-color, 0 0 8px $header-0-shadow-color;
      font-size: 220px;
    }
  }
}

.ql-picker.ql-font {
  .ql-picker-label[data-value="Nunito"],
  .ql-picker-item[data-value="Nunito"] {
    &::before {
      font-family: "Nunito", cursive;
      content: "Nunito" !important;
    }
  }

  .ql-picker-label[data-value="Arial"],
  .ql-picker-item[data-value="Arial"] {
    &::before {
      font-family: "Arial", cursive;
      content: "Arial" !important;
    }
  }

  .ql-picker-label[data-value="Georgia"],
  .ql-picker-item[data-value="Georgia"] {
    &::before {
      font-family: "Georgia", cursive;
      content: "Georgia" !important;
    }
  }

  .ql-picker-label[data-value="Helvetica"],
  .ql-picker-item[data-value="Helvetica"] {
    &::before {
      font-family: "Helvetica", cursive;
      content: "Helvetica" !important;
    }
  }

  .ql-picker-label[data-value="Lucida"],
  .ql-picker-item[data-value="Lucida"] {
    &::before {
      font-family: "Lucida", cursive;
      content: "Lucida" !important;
    }
  }

  .ql-picker-label[data-value="Serif"],
  .ql-picker-item[data-value="Serif"] {
    &::before {
      font-family: "Serif", cursive;
      content: "Serif" !important;
    }
  }

  .ql-picker-label[data-value="Monospace"],
  .ql-picker-item[data-value="Monospace"] {
    &::before {
      font-family: "Monospace", cursive;
      content: "Monospace" !important;
    }
  }
}

/* Set content font-families */
.ql-font-Nunito {
  font-family: "Nunito";
}
.ql-font-Arial {
  font-family: Arial;
}
.ql-font-Georgia {
  font-family: Georgia;
}
.ql-font-Helvetica {
  font-family: "Helvetica";
}
.ql-font-Lucida {
  font-family: "Lucida";
}
.ql-font-Serif {
  font-family: serif;
}
.ql-font-Monospace {
  font-family: Monaco;
}

.ql-size-large {
  font-size: 1.5em;
}

.ql-size-small {
  font-size: 0.75em;
}

.ql-size-huge {
  font-size: 2.5em;
}
