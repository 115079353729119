$header-color: #222;
$header-shadow-color: #c9c9c9;
$header-0-shadow-color: #ffab00;

.cep-not-found {
  height: 100%;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  font-family: "Nunito", cursive;

  .cep-not-found__container {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .cep-not-found__header {
    height: 190px;
  }

  .cep-not-found__header-text {
    font-size: 220px;
    letter-spacing: 10px;
    margin: 0;
    font-weight: 700;
    text-shadow: 2px 2px 0 $header-shadow-color,
      -2px -2px 0 $header-shadow-color;
    color: $header-color;

    .cep-not-found__header-0 {
      text-shadow: 2px 2px 0 $header-0-shadow-color,
        -2px -2px 0 $header-0-shadow-color, 0 0 8px $header-0-shadow-color;
      font-size: 220px;
    }
  }

  .cep-not-found__message {
    text-align: center;
    color: inherit;
    font-size: 18px;
    padding-top: 25px;
  }
}
