html,
body,
#root {
  height: 100%;
  width: 100%;
}

//commented by Yusuf - 19th Dec 2021
// * {
//   font-family: "Nunito", "Roboto", Arial, Helvetica, sans-serif;
// }

body {
  margin: 0;
}

.cep-full-size {
  height: 100%;
  width: 100%;
}

@media print {
  .cep-no-print {
    display: none;
  }
}
