.cep-spinner--centered {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.cep-spinner__text {
  position: absolute;
  top: 55%;
}
