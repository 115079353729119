.cp-not-authorized-wrapper {
  background-color: inherit;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Nunito', cursive;
}

.cp-not-authorized__message {
  font-size: 30px;
  font-weight: 500;
  color: inherit;
}
.cp-not-authorized__sub-message {
  font-size: 18px;
  color: inherit;
  font-weight: 300;
  margin-bottom: 20px;
}
